'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.ContactForm = function ContactForm() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $infoArea = document.querySelectorAll('.input-file-text');
    var $btnOpen = document.querySelector('[data-open-button]');
    var $form = document.querySelector('[data-form]');
    var $inputFiles = document.querySelectorAll('input[type="file"]');

    var $honeypot = document.querySelector('[class="uniq-input-wrapper"]');
    var timer = 5;

    if ($btnOpen) {
      $btnOpen.addEventListener('click', function (e) {
        e.preventDefault();
        openForm();
      });

      openForm = function openForm() {
        $form.style.display = 'block';

        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        var scrollForm = scrollTop + $form.offsetHeight - 90;
        window.scroll({
          top: scrollForm,
          behavior: "smooth"
        });
      };
    }

    if ($inputFiles.length > 0) {
      $inputFiles.forEach(function ($input, i) {

        $input.addEventListener("dragenter", function (event) {
          if (event.target.className == "form-input-file") {
            console.log('enter');
            var el = document.querySelectorAll('.input-file-wrapper');
            el[i].style.backgroundColor = "rgba(10, 192, 10, .1)";
            el[i].style.borderColor = "#0AC00A";
          }
        }, false);

        $input.addEventListener("dragleave", function (event) {
          if (event.target.className == "form-input-file") {
            console.log('exit');
            var el = document.querySelectorAll('.input-file-wrapper');
            el[i].style.backgroundColor = "transparent";
            el[i].style.borderColor = "rgba(255, 255, 255, 0.7)";
          }
        }, false);

        $input.addEventListener('change', function () {
          // let el = document.querySelectorAll('.input-file-wrapper');
          var fileName = $input.files[0].name;
          $infoArea[i].innerHTML = fileName;
        });
      });
    }

    // if ($honeypot) {
    //   let $form = $honeypot.closest('form');
    //   let timeout = setTimeout(function(){
    //   $form.addEventListener('submit', (e) => {
    //     e.preventDefault();
    //     timer -= 1;
    //
    //
    //   });
    //   }, 5000);
    // }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();