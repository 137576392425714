'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Testimonials002 = function Testimonials002() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $sliders = [].slice.call(document.querySelectorAll('.testimonials002-slider'));
    var $slidersNav = [].slice.call(document.querySelectorAll('.testimonials002-sliderNav'));

    if ($sliders) {
      setTimeout(function () {
        $sliders.forEach(function ($slide) {
          var flkty = new Flickity($slide, {
            cellAlign: 'center',
            autoPlay: false, // 5000
            prevNextButtons: true,
            pageDots: false,
            wrapAround: true,
            lazyLoad: true,
            imagesLoaded: true
          });

          // flkty.on('staticClick', (event, pointer, cellElement, cellIndex) => {
          //   if (typeof cellIndex === 'number') {
          //     flkty.selectCell(cellIndex);
          //   }
          // });
        });

        $slidersNav.forEach(function ($sliderNav, i) {
          var flkty = new Flickity($sliderNav, {
            asNavFor: $sliders[i],
            pageDots: false,
            prevNextButtons: false,
            cellAlign: 'left',
            contain: true,
            imagesLoaded: true
          });
        });
      }, 200);
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();