'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Slider004 = function Slider004() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $sliders = [].slice.call(document.querySelectorAll('.slider004'));

    if ($sliders.length) {
      setTimeout(function () {
        $sliders.forEach(function ($slider) {
          var $sliderSection = $slider.querySelector('.slider004-slider');

          var flkty = new Flickity($sliderSection, {
            cellAlign: 'left',
            resize: true,
            imagesLoaded: true,
            adaptiveHeight: true,
            groupCells: true,
            lazyLoad: true,
            wrapAround: $slider.hasAttribute('data-wraparound'),
            pageDots: $slider.hasAttribute('data-dots'),
            prevNextButtons: $slider.hasAttribute('data-prevnextbuttons'),
            autoPlay: $slider.hasAttribute('data-autoplay')
          });
        });
      }, 200);
    }

    var $sliders005 = [].slice.call(document.querySelectorAll('.slider005'));

    if ($sliders005.length) {
      setTimeout(function () {

        $sliders005.forEach(function ($slider) {
          var $sliderSection005 = $slider.querySelector('.slider005-slider');
          var $sliderItems = [].slice.call(document.querySelectorAll('.logo-wrapper'));

          $sliderSection005.addEventListener('mouseenter', function () {

            $sliderItems.forEach(function ($item) {

              $item.classList.add('is-dark');

              $item.addEventListener('mouseenter', function () {

                $item.classList.add('is-light');
              });
            });
          });

          $sliderSection005.addEventListener('mouseleave', function () {

            $sliderItems.forEach(function ($item) {

              $item.classList.remove('is-dark');

              $item.addEventListener('mouseleave', function () {

                $item.classList.remove('is-light');
              });
            });
          });

          var flkty = new Flickity($sliderSection005, {
            cellAlign: 'left',
            resize: true,
            imagesLoaded: true,
            adaptiveHeight: false,
            groupCells: false,
            lazyLoad: true,
            wrapAround: true,
            pageDots: false,
            prevNextButtons: true,
            autoPlay: false
          });
        });
      }, 200);
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();