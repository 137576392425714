'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.ScrollTo = function ScrollTo() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $header = document.querySelector('.section-hero') || document.querySelector('.hero-carousel');

    if ($header) {
      console.log($header.offsetHeight);

      var $scrollLinks = document.querySelector('.scroll-down-indicator');

      if ($scrollLinks) {
        $scrollLinks.addEventListener('click', function (e) {

          e.preventDefault();

          var topPos = $header.offsetHeight;

          window.scroll({
            top: topPos,
            behavior: "smooth"
          });
        });
      }
    }

    // const $scrollLinks = [].slice.call(document.querySelectorAll('a[data-scrollto]'));
    //
    // $scrollLinks.forEach(function (item) {
    //
    //
    //   item.addEventListener('click', function (e) {
    //
    //     e.preventDefault();
    //
    //     console.log(item.dataset.scrollto);
    //
    //     let $zone = document.querySelector(item.dataset.scrollto);
    //
    //     if (item.dataset.target) {
    //       $zone = document.querySelector(item.dataset.target);
    //     };
    //
    //     const topPos = $zone.getBoundingClientRect().top + window.scrollY;
    //
    //     window.scroll({
    //       top: (topPos - 55),
    //       behavior: "smooth"
    //     });
    //   });
    // });

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();