'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Intro001Anim = function ScrollTo() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var color = '#0ac00a';
    var $borderColor = 'white';
    var $circles = document.querySelectorAll('.circle');
    var $bars = document.querySelectorAll('.block-intro001:after');
    console.log('$bars', $bars);

    if ($circles) {
      $circles.forEach(function ($circle) {
        var waypoint = new Waypoint({
          element: $circle,
          handler: function handler(direction) {

            if (direction == "down") {
              var update = function update(e) {

                value += 1;

                if (value >= 100) {
                  clearInterval(anim);
                }

                if (value <= 50) {
                  $circle.style.setProperty('background-image', 'linear-gradient(' + (value * 3.6 + 90) + 'deg, transparent 50%, ' + _color + ' 50%),linear-gradient(90deg, ' + _color + ' 50%, transparent 50%)');
                } else if (value <= 99) {
                  $circle.style.setProperty('background-image', 'linear-gradient(' + (value * 3.6 - 90) + 'deg, transparent 50%, ' + $borderColor + ' 50%),linear-gradient(90deg, ' + _color + ' 50%, transparent 50%)');
                } else {
                  $circle.style.setProperty('background-image', 'none');
                }
              };

              console.log('down');
              var anim = setInterval(update, 5);
              var value = 0;
              var _color = $circle.getAttribute("data-loader-color");
            } else if (direction == "up") {
              console.log('up');
            }
          },
          offset: '80%'
        });
      });
    }
    // window.addEventListener("scroll", function() {
    //   $circles.forEach((circle) => {
    //     circle.style.transform = "rotate("+window.pageYOffset+"deg)";
    //   })
    // });

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();