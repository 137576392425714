'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.TopBanner = function TopBanner() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  var $closeBtn = document.querySelector('[data-modal-close]');
  var $banner = document.querySelector('[data-banner-container]');
  var display = $banner.getAttribute("data-banner-display");
  // console.log($display);
  console.log('$display');

  if (localStorage.getItem('kickstarter') !== 'seen') {
    $banner.style.display = display;

    $closeBtn.addEventListener("click", function () {
      $banner.style.display = 'none';
      localStorage.setItem('kickstarter', 'seen');
    });
  } else {}
  // $banner.style.display = 'none';


  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();