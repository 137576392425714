'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Accordion = function Accordion() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $trigger = document.querySelectorAll('.accordion-row');

    $trigger.forEach(function (item) {

      var title = item.querySelector('.accordion-title');
      var text = item.querySelector('.accordion-text');
      var chevron = title.querySelector('.icon-chevron-right');

      title.addEventListener('click', function () {

        if (item.classList.contains('close')) {

          console.log('toto close');

          item.classList.remove('close');

          chevron.classList.remove('close');

          text.classList.remove('is-active');

          title.classList.remove('is-active');
        } else {

          console.log('toto open');

          item.classList.add('close');

          chevron.classList.add('close');

          text.classList.add('is-active');

          title.classList.add('is-active');
        }
      });
    });

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();